import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResPagination, ResData } from '@/utils/request';
import { Employee } from '@/pages/employee/api';

export interface EmployeeState {
  list: Employee.Self[];
  /** 共用 */
  pagination: Partial<ResPagination>;
  /** 共用 */
  searchData: {
    recordState?: Employee.RecordState['recordState'];
    customerId?: string;
    name?: string;
  };

  detail: Employee.Self;
  siDetailObj: ResData<Employee.SiDetail> & {
    loading: boolean;
  };
}

export const initialSD: EmployeeState['searchData'] = {};

const initialState: EmployeeState = {
  list: [],
  pagination: { number: 0, size: 10 },

  searchData: initialSD,

  detail: {},
  siDetailObj: {
    code: 200,
    message: '',
    data: {},
    loading: false,
  },
};

const slice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    update: (state, { payload }: PayloadAction<Partial<EmployeeState>>) => ({
      ...state,
      ...payload,
    }),
  },
});

export const employeeActions = slice.actions;
export const employeeSlice = slice;
export * as employeeThunks from './thunks';
