import React, { lazy } from 'react';
import { SolutionOutlined, FileAddOutlined } from '@ant-design/icons';
import { listPageTypes } from '@/pages/payroll/constant';
import { RouteItem } from './types';

const PayrollList = React.lazy(
  () => import(/* webpackChunkName: "payroll-list" */ '@/pages/payroll/List'),
);

const addItem: RouteItem = {
  path: 'add',
  component: lazy(() => import(/* webpackChunkName: "payroll-add" */ '@/pages/payroll/Add')),
  title: '新建工资单',
  icon: <FileAddOutlined />,
};

const payroll: RouteItem[] = [
  {
    path: '/payroll',
    label: '工资单',
    icon: <SolutionOutlined />,
    children: [
      { ...addItem, path: `${addItem.path}/:id`, isHidden: true },
      addItem,
      // {
      //   path: 'list',
      //   component: React.lazy(
      //     () => import(/* webpackChunkName: "payroll-list" */ '@/pages/payroll/List'),
      //   ),
      //   title: '月工资单',
      // },
      ...listPageTypes.map<RouteItem>((v) => ({
        path: v.value,
        component: PayrollList,
        title: v.label,
      })),
      {
        path: ':id',
        component: lazy(
          () => import(/* webpackChunkName: "payroll-detail" */ '@/pages/payroll/Detail'),
        ),
        title: '工资单详情',
        isHidden: true,
      },
    ],
  },
];

export default payroll;
