import { configureStore, combineReducers, Action } from '@reduxjs/toolkit';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { message } from 'antd';
import { commonSlice, commonThunks, commonActions } from './common';
import { employeeSlice } from '@/pages/employee/store';
import { employerSlice } from '@/pages/employer/store';
import { salaryTemplateSlice } from '@/pages/salary/Template/store';
import { payrollSlice } from '@/pages/payroll/store';
import { employeeSalarySlice } from '@/pages/salary/employee/store';
import { taxSlice } from '@/pages/salary/tax/store';
import { billSlice } from '@/pages/bill/store';

const rootReducer = combineReducers({
  [commonSlice.name]: commonSlice.reducer,
  [employeeSlice.name]: employeeSlice.reducer,
  [employerSlice.name]: employerSlice.reducer,
  [salaryTemplateSlice.name]: salaryTemplateSlice.reducer,
  [payrollSlice.name]: payrollSlice.reducer,
  [employeeSalarySlice.name]: employeeSalarySlice.reducer,
  [taxSlice.name]: taxSlice.reducer,
  [billSlice.name]: billSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = ThunkDispatch<RootState, unknown, Action<string>>;

/** 这里返回类型一般是 Promise ，其他请自行指定 */
export type AppThunk<R = Promise<void>> = ThunkAction<
  R,
  RootState,
  unknown, // 这个用于定义 redux-thunk extraArgument 类型，目前我们不会用到
  Action<string>
>;

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

store.dispatch(commonThunks.tryGetOauthUser());

/** 用于401(登录过期失效)的message弹框只弹一次 */
let flag401 = false;
window.cb401 = () => {
  // console.log('xx')
  if (!flag401) {
    flag401 = true;
    message.error('登录已过期或失效，请重新登录～', undefined, () => {
      flag401 = false;
    });
    store.dispatch(commonActions.update({ oauthUser: {} }));
  }
};

export default store;
