import request from '@/utils/request';

export declare namespace Agent {
  interface Self {
    /** 关联IAM平台用户ID */
    sub?: string;
    /** 姓名 */
    name?: string;
  }
}

export const agentApi = {
  /** 获取所有经办人 */
  get: () => request.get<Agent.Self[]>('/user/agents'),
};
