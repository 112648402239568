import { lazy } from 'react';
import { RouteItem } from './types';

const common: RouteItem[] = [
  {
    path: '/login',
    component: lazy(
      () => import(/* webpackChunkName: "login" */ '@/pages/common/Login'),
    ),
    title: '登录',
  },
  {
    path: '/404',
    component: lazy(
      () =>
        import(/* webpackChunkName: "not-found" */ '@/pages/common/NotFound'),
    ),
    title: '页面找不到了',
  },
];

export default common;
