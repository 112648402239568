import React from 'react';
import { PayCircleOutlined } from '@ant-design/icons';
import { salaryListPageTypes } from '@/pages/employee/constant';
import { RouteItem } from './types';

const addItem: RouteItem = {
  path: 'template/add',
  component: React.lazy(
    () =>
      import(
        /* webpackChunkName: "salary-template-add-or-edit" */ '@/pages/salary/Template/AddOrEdit'
      ),
  ),
  title: '新建模板',
  isHidden: true,
};

const salary: RouteItem[] = [
  {
    path: '/salary',
    label: '薪酬管理',
    icon: <PayCircleOutlined />,
    children: [
      ...salaryListPageTypes.map<RouteItem>((v) => ({
        path: v.value,
        component: React.lazy(
          () => import(/* webpackChunkName: "employee-list" */ '@/pages/employee/List'),
        ),
        title: v.label,
      })),
      {
        path: 'tax',
        component: React.lazy(
          () => import(/* webpackChunkName: "salary-tax-list" */ '@/pages/salary/tax/List'),
        ),
        title: '个税管理',
      },
      addItem,
      { ...addItem, path: 'template/:id/edit', title: '编辑模板' },
      {
        path: 'template',
        component: React.lazy(
          () =>
            import(/* webpackChunkName: "salary-template-list" */ '@/pages/salary/Template/List'),
        ),
        title: '薪酬模版',
      },

      // {
      //   path: 'reimburse',
      //   component: lazy(
      //     () =>
      //       import(
      //         /* webpackChunkName: "salary-reimburse" */ '@/pages/salary/Reimburse'
      //       ),
      //   ),
      //   title: '报销管理',
      // },
    ],
  },
];

export default salary;
