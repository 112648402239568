import qs from 'qs';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { notification, message } from 'antd';
import constant, { Constant } from '@/constant';
import { Oauth } from '@/api/oauth';

export interface ResData<T = any> {
  code: number;
  message: string;
  data: T;
}

export interface ReqList {
  page?: number;
  size?: number;
  [key: string]: any;
}

export interface ResPagination {
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export interface ResList<T = any> extends ResPagination {
  content: T[];
}

/** 返回的页码数据转成Table的pagination的字段 */
export const toTablePagination = (p: Partial<ResPagination>) => ({
  current: (p.number || 0) + 1,
  pageSize: p.size,
  total: p.totalElements || 0,
});

export const baseURL = (() => {
  const urlMap: { [k in Constant['dataEnv']]: string } = {
    pre: 'https://pre.api.hrm.chengxianghr.cn',
    dev: 'https://api.chengxianghr.cn/test',
  };
  return urlMap[constant.dataEnv];
})();

const request = axios.create({
  baseURL,
  timeout: 0,
  // withCredentials: true,
});

request.interceptors.request.use(
  (oldConfig) => {
    const config = { ...oldConfig };
    // Do something before request is sent
    if (config.apiType === 'oauth') {
      // 登录相关的服务独立，baseURL各环境相同，通过参数区分不同环境
      config.baseURL = 'https://api.chengxianghr.cn';
    }

    if (config.data && (config.apiType === 'oauth' || config.needBodyUrlEncoded)) {
      config.headers = { 'content-type': 'application/x-www-form-urlencoded', ...config.headers };
      config.data = qs.stringify(config.data);
    }

    const oauthToken: Oauth.OauthToken = JSON.parse(
      window.localStorage.getItem(constant.storageKeys.oauthToken) || '{}',
    );
    if (oauthToken.access_token) {
      config.headers = { Authorization: `Bearer ${oauthToken.access_token}`, ...config.headers };
    }

    return config;
  },

  (error) => {
    // Do something with request error
    return Promise.reject(error);
  },
);

request.interceptors.response.use(
  (response) => {
    if (response.config.apiType === 'oauth') {
      return {
        code: response.status,
        message: 'success',
        data: response.data,
      } as ResData;
    }
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response.config.originResponse ? response : response.data;
  },
  (error: AxiosError) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    const { config, response } = error;

    const res: ResData = {
      code: error.response?.data?.status || error.response?.status,
      message: error.response?.data?.message || error.response?.data?.error,
      data: {},
    };
    if (response?.status === 401) {
      window.cb401();
      return res;
    }

    if (config.apiType !== 'oauth') {
      notification.error({
        message: error.response?.status,
        description: error.response?.statusText || error.toJSON()?.['message'] || '网络错误',
        duration: null,
        top: 60,
      });
    }

    return res;
  },
);

/** 导出 */
export const exportExcel = async (url: string, params = {}) => {
  try {
    // @ts-ignore
    const res: AxiosResponse = await request.get(url, {
      params,
      responseType: 'arraybuffer',
      originResponse: true,
    });

    const disposition = res.headers['content-disposition'];
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    let filename = '下载.xlsx';
    if (matches?.[1]) {
      filename = matches[1].replace(/['"]/g, '');
      filename = decodeURIComponent(filename);
      filename = filename.replace('utf-8', '');
    }

    const blob = new Blob([res.data]);
    const eleA = document.createElement('a');
    eleA.download = filename;
    eleA.style.display = 'none';
    eleA.href = URL.createObjectURL(blob);
    document.body.appendChild(eleA);
    eleA.click();
    URL.revokeObjectURL(eleA.href);
    document.body.removeChild(eleA);
  } catch (error) {
    message.error('导出失败，请稍后重试～');
  }
};

export default request;
