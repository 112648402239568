import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import dayjs from 'dayjs';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import 'dayjs/locale/zh-cn';
import App from '@/App';
import store from '@/store';
import './style/index.less';

dayjs.locale('zh-cn');

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <ConfigProvider locale={zhCN}>
          <App />
        </ConfigProvider>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
