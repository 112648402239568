import { constantData } from '@/constant';
import { SalaryTemplate } from '@/pages/salary/Template/api';
import { Payroll } from '@/pages/payroll/api';

export type ListPageType = SalaryTemplate.PayrollType | Extract<Payroll.Status, 'APPROVE'>;

/** 列表页类型 */
export const listPageTypes: Array<{ value: ListPageType; label: string }> = [
  ...constantData.payrollTypes.map((v) => ({ value: v.value, label: v.label })),
  { value: 'APPROVE', label: '工资单审批' },
];
