import { arrToObjByValue } from '@/utils';
import { ReqList, ResData } from '@/utils/request';
import { AppThunk } from '@/store';
import { ListPageType } from '@/pages/employee/constant';
import { Employee, employeeApi } from '@/pages/employee/api';
import { employeeActions } from './index';

type ListParams = {
  contractState?: Employee.LaborContract['contractState'];
  recordState?: Employee.RecordState['recordState'];
  name?: string;
  // 用工单位ID
  customer?: string;
  // 排序规则，默认为“createTime,desc”，asc 正序，desc 倒序，不传排序方式默认按正序
  sort?: string;
};

export const getList =
  (payload: ReqList = {}, pageType: ListPageType = 'list'): AppThunk =>
  async (d, g) => {
    const { page, size, ...rest } = payload;
    const { pagination: curP, searchData } = g().employee;
    let req: ReqList & ListParams = {
      page: page ?? curP.number,
      size: size ?? curP.size,
      ...searchData,
      ...rest,
    };

    if (pageType !== 'list') {
      const { customerId, ...restReq } = req;
      req = { ...restReq, customer: customerId };
    }

    let res: ResData;
    if (pageType === 'list') {
      res = await employeeApi.get(req);
    } else if (pageType === 'contract' || pageType === 'retire') {
      // 员工合同：contractState=1&sort=laborContract.endDate,asc
      // 退休管理：contractState=1&sort=retireDate,asc
      if (pageType === 'contract') {
        req = { ...req, contractState: 1, sort: 'laborContract.endDate,asc' };
      }
      if (pageType === 'retire') {
        req = { ...req, contractState: 1, sort: 'retireDate,asc' };
      }
      res = await employeeApi.getPersonPersonal(req);
    } else if (pageType === 'archive') {
      res = await employeeApi.getPersonRecords(req);
    } else {
      res = await employeeApi.getPersonWages(req);
    }

    const { content, ...pagination } = res.data || {};
    // if (process.env.NODE_ENV === 'development') {
    //   if (res.data.content[2].job) {
    //     res.data.content[2].job.jobState = 0;
    //   }
    // }
    d(employeeActions.update({ list: content || [], pagination }));
  };

export const getDetailIfNeed =
  (id?: string, force = false): AppThunk =>
  async (d, g) => {
    const { detail } = g().employee;
    if (!force && id === detail.id) return;
    if (!id && !detail.id) return;

    const res = await employeeApi.getById(id || detail.id!);
    d(employeeActions.update({ detail: res.data }));
  };

/** 获取社保详情 */
export const getSiDetail =
  (id: string): AppThunk =>
  async (d, g) => {
    const curS = g().employee;
    d(employeeActions.update({ siDetailObj: { ...curS.siDetailObj, loading: true } }));
    const res = await employeeApi.getSiById(id);

    let sd = res.data || {};
    const key: keyof Employee.SiDetailPaymentItem = 'insuranceType';
    sd = {
      ...sd,
      employeePaysByInsuranceType: arrToObjByValue(sd.employeePays || [], key),
      companyPaysByInsuranceType: arrToObjByValue(sd.companyPays || [], key),
    };

    d(employeeActions.update({ siDetailObj: { ...res, data: sd, loading: false } }));
  };
