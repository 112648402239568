import request from '@/utils/request';
import { Payroll } from '@/pages/payroll/api';

/** 公司档案 */
export declare namespace Company {
  interface Self {
    id?: string;
    fullname?: string;
    shortName?: string;
    /** 地区 */
    region?: string;
    /** 社保账户 */
    siAccount?: string;
    /** 公积金账户 */
    housingFundAccount?: string;
    /** 社保规则-个人扣除 */
    employeePayRules?: Company.PayRule[];
    employeePayRulesByFieldId?: Record<string, Company.PayRule>;
    /** 社保规则-公司扣除 */
    companyPayRules?: Company.PayRule[];
    companyPayRulesByFieldId?: Record<string, Company.PayRule>;
    ruleCount?: null;
  }

  /** 计算类型。金额计算每个项目都不同，需要根据computeType不同进行计算 */
  type ComputeType =
    /**
     * 按比例计算
     * 按员工设置的社保基数*扣缴比例计算
     * return Person.siBase * PaymentItem.value
     */
    | 'SCALE'
    /**
     * 固定金额
     * return PaymentItem.value
     */
    | 'FIXED'
    /**
     * 固定基数比例扣除
     * 按固定基数*扣缴比例
     * return PaymentItem.base * PaymentItem.value
     */
    | 'FIX_SCALE'
    /**
     * 按月计算，用于社保/公积金补缴
     */
    | 'FULL_MONTH';

  /** 险种。养老保险 | 医疗保险 | 失业保险 | 工伤保险 | 生育保险。险种根据insuranceType字段对应，内容固定，不是根据name进行显示，每个险种在个人或公司缴纳项中只能出现1次，有些险种没有时不显示相应险种，如有的公司可能没有生育保险（合并入医疗保险一起计算，逻辑上无需处理） */
  type InsuranceType =
    | 'ANNUITY'
    | 'MEDICARE'
    | 'UNEMPLOYMENT'
    | 'INJURE_ACCIDENT'
    | 'MATERNITY';

  /** 缴纳项规则 */
  interface PayRule {
    /** 收入项目id */
    fieldId?: string;
    /** 项目名称 */
    name?: string;
    /** 项目类型 */
    payType?: Payroll.PayType;
    /** 序号 */
    order?: number;
    remark?: string;
    /** 计算类型 */
    computeType?: ComputeType;
    /** 项目基数 */
    base?: number;
    /** 扣缴比例/固定金额 */
    value?: number;
    /** 小数位数 */
    scale?: number;
    insuranceType?: InsuranceType;
  }
}

export const companyApi = {
  get: () => request.get<Company.Self[]>('/company'),
};
