import { useSelector, shallowEqual } from 'react-redux';
import { RouteProps } from 'react-router';
import { Route, Redirect } from 'react-router-dom';
import { RootState } from '@/store/index';
import PageLoading from '@/components/base/PageLoading';

interface Props extends RouteProps {}

const AuthRoute: React.FC<Props> = ({ render, ...rest }) => {
  const { isLogining, oauthUser } = useSelector(
    ({ common }: RootState) => ({
      isLogining: common.isLogining,
      oauthUser: common.oauthUser,
    }),
    shallowEqual,
  );

  if (isLogining) return <PageLoading />;

  return (
    <Route
      {...rest}
      render={
        Object.keys(oauthUser).length === 0
          ? (props) => {
              const { location } = props;
              const redirect = encodeURIComponent(`${location.pathname}${location.search}`);
              return <Redirect to={`/login?redirect=${redirect}`} />;
            }
          : render
      }
    />
  );
};

export default AuthRoute;
