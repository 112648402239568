import React from 'react';
import { GoldOutlined } from '@ant-design/icons';
import { listPageTypes } from '@/pages/employer/constant';
import { RouteItem } from './types';

const addItem = {
  path: 'add',
  component: React.lazy(
    () => import(/* webpackChunkName: "employer-add-or-edit" */ '@/pages/employer/AddOrEdit'),
  ),
  title: '新建用工单位',
  isHidden: true,
};

const employer: RouteItem[] = [
  {
    path: '/employer',
    label: '用工单位',
    icon: <GoldOutlined />,
    children: [
      ...listPageTypes.map<RouteItem>((v) => ({
        path: v.value,
        component: React.lazy(
          () => import(/* webpackChunkName: "employer-list" */ '@/pages/employer/List'),
        ),
        title: v.label,
      })),
      addItem,
      { ...addItem, path: ':id/edit', title: '修改单位资料' },
      {
        path: ':id',
        component: React.lazy(
          () => import(/* webpackChunkName: "employer-detail" */ '@/pages/employer/Detail'),
        ),
        title: '单位详情',
        isHidden: true,
      },
    ],
  },
];

export default employer;
