import { useRouteMatch } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { platformRoutesBreadcrumb } from '@/routes';

const TopBreadcrumb: React.FC = () => {
  const match = useRouteMatch();
  const target = platformRoutesBreadcrumb[match.path];

  return target?.length > 0 ? (
    <Breadcrumb style={{ flexShrink: 0, height: 46 }}>
      {target.map((v) => (
        <Breadcrumb.Item key={v}>{v}</Breadcrumb.Item>
      ))}
    </Breadcrumb>
  ) : null;
};

export default TopBreadcrumb;
