import React from 'react';
import { TeamOutlined } from '@ant-design/icons';
import { employeeListPageTypes } from '@/pages/employee/constant';
import { RouteItem } from './types';

const employee: RouteItem[] = [
  {
    path: '/employee',
    label: '人事管理',
    icon: <TeamOutlined />,
    children: [
      ...employeeListPageTypes.map<RouteItem>((v) => ({
        path: v.value,
        component: React.lazy(
          () => import(/* webpackChunkName: "employee-list" */ '@/pages/employee/List'),
        ),
        title: v.label,
      })),
      {
        path: 'add',
        component: React.lazy(
          () => import(/* webpackChunkName: "employee-add" */ '@/pages/employee/Add'),
        ),
        title: '新建员工',
        isHidden: true,
      },
      {
        path: ':id/:tab',
        component: React.lazy(
          () => import(/* webpackChunkName: "employee-detail" */ '@/pages/employee/Detail'),
        ),
        title: '员工资料详情',
        isHidden: true,
      },
    ],
  },
];

export default employee;
