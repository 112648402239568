import request, { ResList } from '@/utils/request';
import { Employer } from '@/pages/employer/api';

export declare namespace SalaryTemplate {
  /** 薪酬模板 */
  interface Self {
    id?: string; // 模板ID
    payrollType?: SalaryTemplate.PayrollType; // 工资单类型
    name?: string; // 模板名称
    customerId?: string; // 企业ID
    customer?: Employer.EmployerSimple;
    employeeInfoShows?: EmployeeInfoShows;
    // enableTax?: boolean; // 是否计算个税
    taxType?: string; // 计税类型
    payItems?: SalaryTemplate.PayItem[]; // 收入项目
  }

  /** 月工资单 | 福利奖金 */
  type PayrollType = 'MONTH_PAY' | 'BONUS';

  /** 税前收入项。应税工资｜税前扣除｜通讯补贴(特殊类型，会处理个税-其他扣除项算税时，最高免除300元)。https://docs.qq.com/doc/DVG1FWGxQU1FVb3RD */
  type TaxedPayType = 'TAXABLE_PAY' | 'DEDUCTIONS' | 'TAXABLE_PAY_PHONE';
  /** 税后调整项。税后津贴｜税后扣除 */
  type NoTaxedPayType = 'NON_TAXABLE_ALLOWANCES' | 'POST_TAX_DEDUCTIONS';
  /** 收入项目类型 */
  type PayType = TaxedPayType | NoTaxedPayType;

  /** 收入项目 */
  interface PayItem {
    fieldId?: string; // 收入项目id
    name?: string; // 项目名称
    payType?: SalaryTemplate.PayType; // 项目类型
    order?: number; // 序号
    /** 备注 */
    remark?: string;
  }

  /** 员工信息展示规则 */
  interface EmployeeInfoShows {
    shownPhone?: boolean; // 包含手机号
    shownIdNumber?: boolean; // 包含身份证号
    shownPayCard?: boolean; // 包含工资卡号
  }
}

export const salaryTemplateApi = {
  get: (params: { [key: string]: any }) =>
    request.get<ResList<SalaryTemplate.Self>>('/payroll_template', { params }),

  getById: (id: string) => request.get<SalaryTemplate.Self>(`/payroll_template/${id}`),

  /** 选择薪酬模板 */
  getOnSearch: (params: { customerId: string; payrollType: SalaryTemplate.PayrollType }) =>
    request.get<SalaryTemplate.Self[]>('/payroll_template/search', {
      params,
    }),

  add: (data) => request.post<SalaryTemplate.Self>('/payroll_template', data),

  edit: (id: string, data) => request.post<SalaryTemplate.Self>(`/payroll_template/${id}`, data),
};
