import { filterObj } from '@/utils';
import { ReqList } from '@/utils/request';
import { AppThunk } from '@/store';
import { employeeApi, Employee } from '@/pages/employee/api';
import { employerActions } from '.';

export const getEmployees =
  (payload: { customer?: string } & ReqList = {}): AppThunk =>
  async (d, g) => {
    const { page, size, ...rest } = payload;
    const {
      employeesPagination: curP,
      employeesSearchData,
      employeesTotal: curTotal,
    } = g().employer;

    // 默认显示签约状态为已签约
    const otherReq: { contractState: Employee.LaborContract['contractState'] } = {
      contractState: 1,
    };

    const req: ReqList = {
      ...otherReq,
      ...employeesSearchData,
      page: page ?? curP.number,
      size: size ?? curP.size,
      ...rest,
    };
    const res = await employeeApi.getByQuery(req);
    const { content, ...pagination } = res.data || {};

    const isInitCondition = (() => {
      const temp = filterObj(employeesSearchData);
      return Object.keys(temp).length === 1 && temp.jobState === 1;
    })();

    d(
      employerActions.update({
        employees: content || [],
        employeesPagination: pagination,
        employeesTotal: isInitCondition ? pagination.totalElements : curTotal,
      }),
    );
  };
