import constant, { Constant } from '@/constant';
import request from '@/utils/request';

const clientMap: { [k in Constant['dataEnv']]: { client_id: string; client_secret: string } } = {
  pre: {
    client_id: '3b6e9db6df4d',
    client_secret: 'ba50a94d9423ac8271bdb71c088c4229b06c4bcb05324d1b2d965ab0507b4aa8',
  },
  dev: {
    client_id: 'af3554b60543',
    client_secret: 'd466402a2533f500654cf4738df60d3d2ac481780fd7122acd3e0f14ae41a843',
  },
};

export declare namespace Oauth {
  interface OauthToken {
    /** 授权令牌，其他请求时需携带 */
    access_token: string;
    /** Access Token到期时间，单位秒，从签发起计算 */
    expires_in: number;
    /** OIDC规范，包含用户的基本信息 */
    id_token: string;
    /** 刷新令牌，access_token过期后，通过刷新令牌获得一个新的 */
    refresh_token: string;
    /** 令牌承载方式，在Header中增加Bearer Auth承载access_token */
    token_type: string;
    /** 入参如有，会按原样返回 */
    state?: string;
  }

  /** 角色。管理员 | 业务员 | 主管 | 财务 | 档案管理员 */
  type Role =
    | 'ROLE_ADMIN'
    | 'ROLE_SALES'
    | 'ROLE_MANAGER'
    | 'ROLE_FINANCIAL'
    | 'ROLE_RECORD_KEEPER';

  interface User {
    // JWT规范，同User ID
    sub?: string;
    // 用户名
    username?: string;
    // 姓名
    name?: string;
    // 手机号码
    phone_number?: string;
    // 手机号是否已验证
    phone_verified?: boolean;
    // 邮箱
    email?: string;
    // 邮箱是否已验证
    email_verified?: boolean;
    // 创建时间
    created_at?: string;
    // 最后更新
    updated_at?: string;
    /** 角色 */
    roles?: Role[];
    /** 角色映射。返回数据中没有，自行添加 */
    roleMap?: { [key in Role]?: boolean };
  }
}

export const oauthApi = {
  login: ({ username, password }) =>
    request.post<Oauth.OauthToken>(
      '/oauth/token',
      {
        ...clientMap[constant.dataEnv],
        grant_type: 'password',
        username,
        password,
      },
      { apiType: 'oauth' },
    ),

  getUser: () => request.get<Oauth.User>('/oauth/userinfo', { apiType: 'oauth' }),
};
