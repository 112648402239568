import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Dropdown, Menu } from 'antd';
// import { QuestionCircleOutlined, BellOutlined } from '@ant-design/icons';
import { RootState } from '@/store';
import { commonThunks } from '@/store/common';
import logoImg from '@/assets/images/logo.png';
import loginAvatarImg from '@/assets/images/login-avatar.png';
import styles from './index.module.less';

const TopHeader: React.FC = () => {
  const dispatch = useDispatch();
  const { oauthUser } = useSelector(
    ({ common }: RootState) => ({
      oauthUser: common.oauthUser,
    }),
    shallowEqual,
  );

  const userMenu = (
    <Menu>
      <Menu.Item key="logout" onClick={() => dispatch(commonThunks.logout())}>
        退出
      </Menu.Item>
    </Menu>
  );

  return (
    <header className={styles.header}>
      <div className={styles.header__fixed}>
        <img className={styles.logo} src={logoImg} alt="logo" />
        <div className={styles.title}>人力资源管理</div>
        <div className={styles.bar}></div>
        <div className={styles.name}>舟山市人才发展集团</div>
        <Menu mode="horizontal" selectedKeys={['1']} className={styles.menu}>
          <Menu.Item key="1">薪酬档案</Menu.Item>
          <Menu.Item key="2">
            <a
              href="https://lowcode-9g0gt400aacb4a3a-1317815969.tcloudbaseapp.com/adminportal/#/app/app-hsqar367?envType=prod/"
              target="_blank"
              rel="noopener noreferrer"
            >
              招聘管理
            </a>
          </Menu.Item>
        </Menu>
        <div style={{ flexGrow: 1 }}></div>
        {/* <QuestionCircleOutlined className={styles.icon} />
        <BellOutlined className={styles.icon} /> */}
        <Dropdown overlay={userMenu}>
          <div className={styles.user}>
            <img
              className="avatar size24"
              src={loginAvatarImg}
              alt="avatar"
              style={{ marginRight: 8 }}
            />
            {oauthUser.name}
          </div>
        </Dropdown>
      </div>
    </header>
  );
};

export default TopHeader;
