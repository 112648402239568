import React from 'react';
import { ReconciliationOutlined } from '@ant-design/icons';
import { listPageTypes } from '@/pages/bill/constant';
import { RouteItem } from './types';

const bill: RouteItem[] = [
  {
    path: '/bill',
    label: '结算统计',
    icon: <ReconciliationOutlined />,
    children: listPageTypes.map<RouteItem>((v) => ({
      path: v.value,
      component: React.lazy(() => import(/* webpackChunkName: "bill-list" */ '@/pages/bill/List')),
      title: v.label,
    })),
  },
];

export default bill;
