import { InputNumberProps } from 'antd';
// import constant from '.';
import regular from './regular';

const antd = {
  datePicker: {
    dateFormat: 'YYYY年MM月DD日',
    monthFormat: 'YYYY年MM月',
  },

  pagination: {
    showTotal: (t) => `共 ${t} 条`,
    showTotalRange: (t, r) => `第 ${r[0]}~${r[1]} 条 / 共 ${t} 条`,
  },

  inputNumberCommonProps: {
    // formatter: (v) =>
    //   `¥ ${constant.numFormatWithTwoFraction.format(Number(v))}`,
    formatter: (v) => `¥ ${v}`,
    parser: (v) => v?.replace(regular.invalidNumCharacter, '') || '',
  } as InputNumberProps,
};

export default antd;
