import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dayjs } from 'dayjs';
import constant from '@/constant';
import { ResPagination } from '@/utils/request';
import { Tax } from '@/pages/salary/tax/api';

export interface TaxState {
  list: Tax.Self[];
  pagination: Partial<ResPagination>;
  searchData: {
    name?: string;
    customer?: string;
    year?: string;
    yearDayjs?: Dayjs;
  };
}

export const initialSD: TaxState['searchData'] = {
  yearDayjs: constant.today,
};

const initialState: TaxState = {
  list: [],
  pagination: { number: 0, size: 10 },
  searchData: initialSD,
};

const slice = createSlice({
  name: 'tax',
  initialState,
  reducers: {
    update: (state, { payload }: PayloadAction<Partial<TaxState>>) => ({
      ...state,
      ...payload,
    }),
  },
});

export const taxActions = slice.actions;
export const taxSlice = slice;
export * as taxThunks from './thunks';
