import { AppThunk } from '@/store';
import { ReqList } from '@/utils/request';
import { salaryTemplateApi } from '@/pages/salary/Template/api';
import { salaryTemplateActions } from '.';

export const getList = (payload: ReqList = {}): AppThunk => async (d, g) => {
  const { page, size, ...rest } = payload;
  const { pagination: preP } = g().salaryTemplate;
  const req: ReqList = {
    page: page ?? preP.number,
    size: size ?? preP.size,
    ...rest,
  };
  const res = await salaryTemplateApi.get(req);
  const { content, ...pagination } = res.data;
  d(salaryTemplateActions.update({ list: content || [], pagination }));
};

export const getDetailIfNeed = (id: string, force = false): AppThunk => async (
  d,
  g,
) => {
  if (!force && id === g().salaryTemplate.detail.id) return;

  const res = await salaryTemplateApi.getById(id);
  d(salaryTemplateActions.update({ detail: res.data || {} }));
};
