import { useEffect, useRef, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Menu } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { platformRoutes } from '@/routes';
import { RouteItem } from '@/routes/types';
import styles from './index.module.less';

const { SubMenu } = Menu;

const renderMenuItems = (routes: RouteItem[], parentPath = '') =>
  routes.reduce((acc: JSX.Element[], r) => {
    if (r.isHidden) {
      return acc;
    }

    const title = r.label || r.title;
    const icon = r.icon || <CheckCircleOutlined />;

    if (r.children?.length) {
      acc.push(
        <SubMenu key={r.path} title={title} icon={icon}>
          {renderMenuItems(r.children, r.path)}
        </SubMenu>,
      );
      return acc;
    }

    const finalPath = `${parentPath}${r.path[0] === '/' ? '' : '/'}${r.path}`;
    acc.push(
      <Menu.Item key={finalPath} icon={parentPath ? null : icon} style={{ paddingLeft: 16 }}>
        <Link to={finalPath}>{title}</Link>
      </Menu.Item>,
    );

    return acc;
  }, []);

const platformMenuItems = renderMenuItems(platformRoutes);

const LeftMenu: React.FC = () => {
  const location = useLocation();

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  useEffect(() => {
    // 根据当前路由来决定哪些菜单是被选中的，这里要配合着如何渲染菜单项的key来看。这里要注意，在某些情况下(比如详情页，id参数在路由的params中)，这里生成的keys将会多余，但是不要紧，不影响
    const temp = location.pathname
      .split('/')
      .filter(Boolean)
      .reduce((acc: string[], cur) => {
        const next = `${acc[acc.length - 1] || ''}/${cur}`;
        acc.push(next);
        return acc;
      }, []);
    setSelectedKeys(temp);
  }, [location.pathname]);

  const defaultOpenKeysRef = useRef<string[]>([]);
  if (defaultOpenKeysRef.current.length === 0) {
    // 我们在首次渲染菜单时，根据当前路由计算需要展开哪些菜单。注意我们只需要在首次进行计算，所以我们使用ref来标记
    const temp = location.pathname.split('/').filter(Boolean);
    defaultOpenKeysRef.current = [`/${temp[0]}`]; // 根据目前的菜单层级来看(只有二级菜单)，我们只需要取第一项即可

    /** 让它一直展开 */
    const alwaysOpen = '/payroll';
    if (defaultOpenKeysRef.current[0] !== alwaysOpen) {
      defaultOpenKeysRef.current.push(alwaysOpen);
    }
  }

  return (
    <nav className={styles.nav}>
      <div className={styles.nav__fixed}>
        <Menu
          className={styles.menu}
          mode="inline"
          selectedKeys={selectedKeys}
          defaultOpenKeys={defaultOpenKeysRef.current}
        >
          {platformMenuItems}
        </Menu>
      </div>
    </nav>
  );
};

export default LeftMenu;
