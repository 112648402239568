import commonRoutes from './common';
import overviewRoutes from './overview';
import payrollRoutes from './payroll';
import salaryRoutes from './salary';
import employeeRoutes from './employee';
import employerRoutes from './employer';
import billRoutes from './bill';

export { commonRoutes };

export const platformRoutes = [
  ...overviewRoutes,
  ...payrollRoutes,
  // ...payrollAddRoutes,
  ...salaryRoutes,
  ...employeeRoutes,
  ...employerRoutes,
  ...billRoutes,
];

export const platformRoutesBreadcrumb = platformRoutes.reduce((acc, cur) => {
  if (cur.children?.length) {
    cur.children.forEach((sub) => {
      const key = `${cur.path}${sub.path[0] === '/' ? '' : '/'}${sub.path}`;
      acc[key] = [cur.title || cur.label, sub.title || sub.label];
    });
    return acc;
  }

  acc[cur.path] = [cur.parentBreadcrumb, cur.title || cur.label].filter(Boolean);
  return acc;
}, {});
