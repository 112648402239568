import request, { ResList } from '@/utils/request';

export declare namespace Employer {
  /** 客户企业信息 */
  interface Self {
    id?: string;
    fullName?: string; // 企业全称
    shortName?: string; // 企业简称
    license?: string; // 营业执照
    address?: Address;
    contacts?: Array<Contact>;
    bank?: string; // 开户银行
    account?: string; // 结算账户
    agent?: string; // 经办人
    hireDisabled?: boolean; // 可以安排残疾员工
    /** 结算公司ID */
    companyId?: string;
    businessContract?: BusinessContract;
    creatTime?: string; // 创建时间
  }

  /** 联系地址 */
  interface Address {
    province: string; // 省
    city: string; // 市
    district: string; // 区
    detailedAdd: string; // 详细地址
  }

  /** 联系人 */
  interface Contact {
    name: string; // 姓名
    phone: string; // 联系电话
  }

  /** 是否已签约，未签约 | 已签约 | 已解约 */
  type BusinessContractState = 'UNSIGNED' | 'SIGNED' | 'CANCELED';

  /** 合作协议 */
  interface BusinessContract {
    contractState: BusinessContractState;
    contractNo: string; // 协议编号
    oldContractNo: string; // 协议编号（原）
    signDate: string; // 签约日期
    cancelDate: string; // 解约日期
  }

  type EmployerSimple = Pick<Employer.Self, 'id' | 'fullName' | 'shortName'>;
}

export const employerApi = {
  get: (params?: { [key: string]: any }) =>
    request.get<ResList<Employer.Self>>('/customer', { params }),

  getById: (id: string) => request.get<Employer.Self>(`/customer/${id}`),

  add: (data: Employer.Self) => request.post<Employer.Self>('/customer', data),

  edit: (id: string, data: Employer.Self) => request.post<Employer.Self>(`/customer/${id}`, data),

  /** 签约 */
  sign: (id: string, data) => request.post<Employer.Self>(`/customer/${id}/sign`, data),

  /** 解约 */
  contractCancel: (id: string, data) =>
    request.post<Employer.Self>(`/customer/${id}/contract_cancel`, data),

  getNameOnly: (params?: { page?: number; size?: number; state?: string; fullName?: string }) =>
    request.get<ResList<Employer.EmployerSimple>>('/customer/nameonly', { params }),
};
