import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Oauth } from '@/api/oauth';
import { Dict } from '@/api/dict';
import { Setting } from '@/api/setting';
import { Company } from '@/api/company';
import { Agent } from '@/api/agent';

export interface CommonState {
  /** 是否正在登录中 */
  isLogining: boolean;
  /** 登录账号的用户信息 */
  oauthUser: Oauth.User;

  dictByCode: {
    ID_TYPE: Dict;
    EDU: Dict;
    PAYROLL_TYPE: Dict;
    SI_COMPANY_ACCOUNT: Dict;
    INSURANCE_TYPE: Dict<Company.InsuranceType>;
    YEAR_SELECT: Dict;
  };

  setting: Setting;
  /** 所有接收单位 */
  companys: {
    loading: boolean;
    data: Company.Self[];
    byId: Record<string, Company.Self>;
  };
  agents: Agent.Self[];
}

const initialState: CommonState = {
  isLogining: false,
  oauthUser: {},

  dictByCode: {
    ID_TYPE: {},
    EDU: {},
    PAYROLL_TYPE: {},
    SI_COMPANY_ACCOUNT: {},
    INSURANCE_TYPE: {},
    YEAR_SELECT: {},
  },

  setting: {},
  companys: {
    loading: false,
    data: [],
    byId: {},
  },
  agents: [],
};

const slice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    /** 这种方式是不推荐的，每个reducer应该都独立定义，但是为了方便直接使用这种方式 */
    update: (state, { payload }: PayloadAction<Partial<CommonState>>) => ({
      ...state,
      ...payload,
    }),
  },
});

export const commonActions = slice.actions;
export const commonSlice = slice;
export * as commonThunks from './thunks';
