import { dayjsToStr } from '@/utils';
import { ReqList, exportExcel } from '@/utils/request';
import { AppThunk } from '@/store';
import { Bill, billApi } from '@/pages/bill/api';
import { billActions, BillState } from '.';

const sdToReq = (sd: BillState['searchData']) => {
  const { startEndMonth: se, ...rest } = sd;
  const res: BillState['searchData'] = {
    ...rest,
    startMonth: dayjsToStr(se?.[0], 3),
    endMonth: dayjsToStr(se?.[1], 3),
  };
  return res;
};

export const getList =
  (payload: ReqList = {}): AppThunk =>
  async (d, g) => {
    const { page, size, ...rest } = payload;
    const { pagination: curP, searchData } = g().bill;
    const req: ReqList = {
      ...sdToReq(searchData),
      page: page ?? curP.number,
      size: size ?? curP.size,
      ...rest,
    };

    const res = await billApi.getAnalysis(req);
    const { content, ...pagination } = res.data || {};
    d(billActions.update({ list: content || [], pagination }));
  };

/** 导出报表  */
export const exportExc =
  (r: Bill.Self): AppThunk =>
  async (d, g) => {
    const { startMonth, endMonth } = sdToReq(g().bill.searchData);
    await exportExcel('/bill/analysis_export', {
      startMonth,
      endMonth,
      customerId: r.customerId,
    });
  };
