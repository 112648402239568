import TopHeader from '@/App/TopHeader';
import LeftMenu from '@/App/LeftMenu';
import styles from './index.module.less';

const Platform: React.FC = ({ children }) => (
  <section className={styles.platform}>
    <TopHeader />
    <section className={styles.middle}>
      <LeftMenu />
      <main className={styles.main}>{children}</main>
    </section>
  </section>
);

export default Platform;
