import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResPagination } from '@/utils/request';
import { Employer } from '@/pages/employer/api';
import { Employee } from '@/pages/employee/api';

export interface EmployerState {
  list: Employer.Self[];
  pagination: Partial<ResPagination>;
  searchData: {
    fullName?: string;
  };
  detail: Partial<Employer.Self>;

  employees: Employee.Self[];
  employeesPagination: Partial<ResPagination>;
  employeesSearchData: {
    name?: string;
    jobState?: Employee.JobState['jobState'];
  };
  /** 现代理人数（按默认规则查询到的人数） */
  employeesTotal?: number;
}

export const initialSD: EmployerState['searchData'] = {};
export const initialEmployeesSD: EmployerState['employeesSearchData'] = {
  jobState: 1,
};

const initialState: EmployerState = {
  list: [],
  pagination: { number: 0, size: 10 },
  searchData: initialSD,
  detail: {},

  employees: [],
  employeesPagination: { number: 0, size: 10 },
  employeesSearchData: initialEmployeesSD,
  employeesTotal: undefined,
};

const slice = createSlice({
  name: 'employer',
  initialState,
  reducers: {
    update: (state, { payload }: PayloadAction<Partial<EmployerState>>) => ({
      ...state,
      ...payload,
    }),
  },
});

export const employerActions = slice.actions;
export const employerSlice = slice;
export * as employerThunks from './thunks';
