import request from '@/utils/request';

export interface Setting {
  id?: string;
  code?: string; // 配置code
  name?: string; // 配置名称
  settings?: SocialInsuranceSetting;
}

export interface SocialInsuranceSetting {
  personRate?: SocialInsuranceRate;
  customerRate?: SocialInsuranceRate;
}

/** 社保缴纳比例 */
export interface SocialInsuranceRate {
  annuity?: number; // 养老保险
  medicare?: number; // 医疗保险
  unemployment?: number; // 失业保险
  injureAccident?: number; // 工伤保险
  maternity?: number; // 生育保险
}

export const settingApi = {
  /** 获取社保扣除比例规则，包含个人和企业扣除比例 */
  get: () => request.get<Setting>('/setting/si_rate'),
};
