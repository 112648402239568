import dayjs from 'dayjs';

const constant = {
  title: '劳务派遣人事管理系统',

  today: dayjs(),

  numFormat: new Intl.NumberFormat(undefined, { maximumFractionDigits: 2 }),
  numFormatWithTwoFraction: new Intl.NumberFormat(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }),

  /** 环境。pre(预发) | dev(开发，目前开发测试一样) */
  dataEnv: (() => {
    const host = window.location.host;
    if (host === 'pre.hrm.chengxianghr.cn') return 'pre';
    return 'dev';
  })() as 'pre' | 'dev',

  storageKeys: {
    oauthToken: 'oauthToken',
  },
};

export { default as constantAntd } from './antd';
export * as constantData from './data';
export { default as constantRegular } from './regular';

export type Constant = typeof constant;
export default constant;
