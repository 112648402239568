import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const PageLoading: React.FC = () => (
  <div style={{ textAlign: 'center', paddingTop: 150 }}>
    <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
  </div>
);

export default PageLoading;
