import { ReqList } from '@/utils/request';
import { AppThunk } from '@/store';
import { ListPageType } from '@/pages/employer/constant';
import { employerApi, Employer } from '@/pages/employer/api';
import { employerActions } from '.';

type ListParams = {
  state?: Employer.BusinessContractState;
  sort?: string;
};

export const getList =
  (payload: ReqList = {}, pageType: ListPageType = 'list'): AppThunk =>
  async (d, g) => {
    const { page, size, ...rest } = payload;
    const { pagination: curP, searchData } = g().employer;
    let req: ReqList & ListParams = {
      ...searchData,
      page: page ?? curP.number,
      size: size ?? curP.size,
      ...rest,
    };

    // 单位合同页：state=SIGNED&sort=businessContract.cancelDate
    if (pageType === 'contract') {
      req = { ...req, state: 'SIGNED', sort: 'businessContract.cancelDate' };
    }

    const res = await employerApi.get(req);
    const { content, ...pagination } = res.data || {};
    d(employerActions.update({ list: content || [], pagination }));
  };

export const getDetailIfNeed =
  (id: string, force = false): AppThunk =>
  async (d, g) => {
    if (!force && id === g().employer.detail.id) return;

    const res = await employerApi.getById(id);
    d(employerActions.update({ detail: res.data || {} }));
  };

export * from './employeeThunks';
