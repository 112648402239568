import { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import constant from '@/constant';
import { commonRoutes, platformRoutes } from '@/routes';
import { RouteItem } from '@/routes/types';
import PageLoading from '@/components/base/PageLoading';
import AuthRoute from '@/components/AuthRoute';
import Platform from '@/App/Platform';
import TopBreadcrumb from '@/App/TopBreadcrumb';

const commonRouteComps = commonRoutes.map(({ path, component: Component, title }) => (
  <Route
    key={path}
    path={path}
    render={() => {
      document.title = title || constant.title;
      return <Component />;
    }}
  />
));

const renderPlatformRoutes = (
  routes: RouteItem[],
  resRoutes: JSX.Element[] = [],
  parentPath = '',
) =>
  routes.reduce((acc, r) => {
    if (r.children?.length) {
      return renderPlatformRoutes(r.children, acc, r.path);
    }

    const finalPath = `${parentPath}${r.path[0] === '/' ? '' : '/'}${r.path}`;
    acc.push(
      <Route
        key={finalPath}
        path={finalPath}
        render={() => {
          document.title = r.title || constant.title;
          // window.scrollTo({ top: 0, behavior: 'smooth' });
          const { component: Component } = r;
          return (
            <>
              <TopBreadcrumb />
              <Component />
            </>
          );
        }}
      />,
    );
    return acc;
  }, resRoutes);

const platformRouteComps = renderPlatformRoutes(platformRoutes);

const App: React.FC = () => {
  return (
    <Suspense fallback={<PageLoading />}>
      <Switch>
        <Redirect path="/" exact to={platformRoutes[0].path} />

        {commonRouteComps}

        <AuthRoute
          render={() => (
            <Platform>
              <Suspense fallback={<PageLoading />}>
                <Switch>
                  {platformRouteComps}
                  <Redirect path="*" to="/404" />
                </Switch>
              </Suspense>
            </Platform>
          )}
        />
      </Switch>
    </Suspense>
  );
};

export default App;
