import { BadgeProps } from 'antd';
import { arrToObjByValue } from '@/utils';
import { Employee } from '@/pages/employee/api';
import { Employer } from '@/pages/employer/api';
import { SalaryTemplate } from '@/pages/salary/Template/api';
import { Payroll } from '@/pages/payroll/api';

export type EnumConstantItem<ValueType = string | number> = {
  value: NonNullable<ValueType>;
  label: string;
  status?: BadgeProps['status'];
  color?: BadgeProps['color'];
  /** 给文字的类名，比如置灰 */
  className?: string;
  /** 额外说明 */
  tip?: string;
};

export const genders: EnumConstantItem<string>[] = ['男', '女'].map((v) => ({
  value: v,
  label: v,
}));

/** 户口类型。household register type */
export const hhrTypes: EnumConstantItem<string>[] = ['非农', '农村'].map((v) => ({
  value: v,
  label: v,
}));

/** 工作状态 */
export const jobStates: EnumConstantItem<Employee.JobState['jobState']>[] = [
  { value: 0, label: '离岗', status: 'default' },
  { value: 1, label: '在岗', status: 'success' },
];
export const jobStatesByValue = arrToObjByValue(jobStates);

/** 员工合同状态。是否已签约 */
export const laborContractStates: EnumConstantItem<Employee.LaborContract['contractState']>[] = [
  { value: 0, label: '未签约', color: 'blue' },
  { value: 1, label: '已签约', status: 'success' },
  { value: 2, label: '已解除', status: 'default', className: 'gray' },
];
export const laborContractStatesByValue = arrToObjByValue(laborContractStates);

/** 档案状态 */
export const recordStates: EnumConstantItem<Employee.RecordState['recordState']>[] = [
  { value: 0, label: '未接收', color: 'blue' },
  { value: 1, label: '已接收', status: 'success' },
  { value: 2, label: '已转出', status: 'default', className: 'gray' },
  { value: 3, label: '已入库', status: 'success' },
];
export const recordStatesByValue = arrToObjByValue(recordStates);

/** 经办人。具体看 https://github.com/longthi/HRO-Manage-System/issues/9 */
// prettier-ignore
const agents = ['颜瑾', '王艳', '钱挺', '沈海波', '王玲', '周波', '夏媛', '王勇', '鲁蓓', '袁鹏'];
/** 经办人，value为key，可作为AutoComplete的options参数 */
export const agentsOptions = agents.map((v) => ({ value: v }));

export const businessContractStates: EnumConstantItem<Employer.BusinessContractState>[] = [
  { value: 'UNSIGNED', label: '未签约', color: 'blue' },
  { value: 'SIGNED', label: '已签约', status: 'success' },
  { value: 'CANCELED', label: '已解约', status: 'default', className: 'gray' },
];
export const businessContractStatesByValue = arrToObjByValue(businessContractStates);

/** 专项附加扣除类型 */
export const specialDeductions: EnumConstantItem<
  NonNullable<Employee.SpecialDeductionItem['type']>
>[] = [
  { value: 'CHILDREN_EDU', label: '子女教育' },
  { value: 'ELDERLY_CARE', label: '赡养老人' },
  { value: 'RENT', label: '住房租金' },
  { value: 'HOUSING_LOAN_INTERESTS', label: '住房贷款利息' },
  { value: 'CONTINUING_EDU', label: '继续教育' },
  { value: 'SERIOUS_DISEASE_TREATMENT', label: '大病医疗' },
];
export const specialDeductionsByValue = arrToObjByValue(specialDeductions);

export const payrollTypes: EnumConstantItem<SalaryTemplate.PayrollType>[] = [
  { value: 'MONTH_PAY', label: '月工资单' },
  { value: 'BONUS', label: '福利奖金' },
];
export const payrollTypesByValue = arrToObjByValue(payrollTypes);

export const serviceTypes: EnumConstantItem<Payroll.ServiceType>[] = [
  { value: 'DISPATCH', label: '派遣' },
  { value: 'OUTSOURCING', label: '外包' },
];
export const serviceTypesByValue = arrToObjByValue(serviceTypes);

/** 工资单计税方式 */
export const taxModes: EnumConstantItem<Payroll.TaxMode>[] = [
  {
    value: 'NORMAL',
    label: '默认',
    tip: '按综合所得累计计税，当月首张工资单的扣除项包含减除费用和专项附加扣除',
  },
  { value: 'ALONE', label: '单独计税', tip: '一次性扣税，且不算入年度累计计税' },
  { value: 'WITH_MONTH', label: '同月工资单合并计税', tip: '延至下次月工资单合并计税' },
  { value: 'NOT_COMPUTE', label: '不计个税' },
];

export const payrollStatus: EnumConstantItem<Payroll.Status>[] = [
  { value: 'CREATING', label: '创建中', status: 'default' },
  { value: 'AWAITING', label: '待提交', status: 'processing' },
  { value: 'APPROVE', label: '审批中', status: 'warning' },
  { value: 'APPROVED', label: '审批通过', status: 'success' },
  { value: 'DECLINED', label: '审批拒绝', status: 'error' },
  { value: 'COMPLETE', label: '完成', status: 'success' },
];
export const payrollStatusByValue = arrToObjByValue(payrollStatus);

/** 服务费类型 */
export const serviceFeeTypes: EnumConstantItem<Payroll.ServiceFee['type']>[] = [
  { value: 'AGENT', label: '代理费' },
  { value: 'SERVICE', label: '服务费' },
  { value: 'DISABILITY', label: '残联金' },
  { value: 'OTHER', label: '其他' },
];
export const serviceFeeTypesByValue = arrToObjByValue(serviceFeeTypes);
