import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResPagination } from '@/utils/request';
import { Employee } from '@/pages/employee/api';

export interface EmployeeSalaryState {
  searchData: { employerId?: string };
  list: Employee.Self[];
  pagination: Partial<ResPagination>;
}

const initialState: EmployeeSalaryState = {
  searchData: {},
  list: [],
  pagination: { number: 0, size: 10 },
};

const slice = createSlice({
  name: 'employeeSalary',
  initialState,
  reducers: {
    update: (
      state,
      { payload }: PayloadAction<Partial<EmployeeSalaryState>>,
    ) => ({
      ...state,
      ...payload,
    }),
  },
});

export const employeeSalaryActions = slice.actions;
export const employeeSalarySlice = slice;
export * as employeeSalaryThunks from './thunks';
