import { Dayjs } from 'dayjs';
import { Employee } from '@/pages/employee/api';

/** 人事管理下的和薪酬管理下部分页面共用，这里是页面类型 */
export type ListPageType = 'list' | 'contract' | 'retire' | 'archive' | 'wage' | 'si' | 'hf';
/** 人事管理下的页面 */
export const employeeListPageTypes: Array<{ value: ListPageType; label: string }> = [
  { value: 'list', label: '员工资料' },
  { value: 'contract', label: '员工合同' },
  { value: 'retire', label: '退休管理' },
  { value: 'archive', label: '员工档案' },
];
/** 薪酬管理下的页面 */
export const salaryListPageTypes: Array<{ value: ListPageType; label: string }> = [
  { value: 'wage', label: '员工薪酬' },
  { value: 'si', label: '社保管理' },
  { value: 'hf', label: '公积金管理' },
];
export const listPageTypes = [...employeeListPageTypes, ...salaryListPageTypes];

export type LeftMenu = 'basic-info' | 'salary' | 'si-hf' | 'income-tax' | 'record';
/** 详情页内部左侧菜单 */
export const leftMenus: Array<{ path: LeftMenu; label: string }> = [
  { label: '基本信息', path: 'basic-info' },
  { label: '工资薪酬', path: 'salary' },
  { label: '社保公积金', path: 'si-hf' }, // social insurance , housing fund
  { label: '个人所得税', path: 'income-tax' },
  // { label: '档案管理', path: 'archive' },
  { label: '记录日志', path: 'record' },
];

/** 新建/编辑员工表单 */
export type FormValue = Employee.Self & {
  birthdayDayjs?: Dayjs;
};
