import request from '@/utils/request';

export type PayrollTypeUnion = 'MONTH_PAY' | 'BONUS';

/** 字典项 */
export interface DictItem<VALUE = string> {
  name?: string; // 名称，用于显示
  value?: VALUE; // 值，用于传值
  sort?: number; // 序号
  desc?: string; // 描述
}

/** 系统字典 */
export interface Dict<ITEM_VALUE = string> {
  id?: string;
  name?: string; // 名称
  code?: string; // 字典编码，方便引用，不可重复
  explain?: string; // 说明
  dictItems?: DictItem<ITEM_VALUE>[];

  // 以下非返回字段，额外添加
  loading?: boolean; // 是否正在加载
  sorted?: DictItem[]; // 使用sort字段排序过的
  byId?: Record<string, DictItem>;
}

export const dictApi = {
  getByCode: (code: string) => request.get<Dict>(`/dict/${code}`),
};

// request.get('/dict');
