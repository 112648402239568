import request, { ResList } from '@/utils/request';
import { Employer } from '@/pages/employer/api';
import { SalaryTemplate } from '@/pages/salary/Template/api';

export declare namespace Payroll {
  /** 工资单 */
  interface Self {
    id?: string; // 工资单ID
    name?: string; // 工资单名称
    /** 员工信息展示规则 */
    employeeInfoShows?: SalaryTemplate.EmployeeInfoShows;
    customerId?: string; // 用工单位ID
    customer?: Pick<Employer.Self, 'id' | 'fullName' | 'shortName' | 'companyId'>;
    /** 类型 */
    payrollType?: SalaryTemplate.PayrollType;
    /** 薪酬模板ID */
    templateId?: string;
    // templateInfo?: SalaryTemplate.Self;
    /** 薪酬模板名称 */
    templateName?: string;
    /** 财年 */
    fiscalYear?: number;
    /** 月份 */
    payMonth?: number;
    service?: ServiceType; // 服务项目
    /** 计税方式 */
    taxMode?: TaxMode;
    /** 计税月份 */
    taxMonth?: string;
    /** 合并计税单 */
    taxPayrollWith?: string[];
    payDate?: string; // 支付日期
    // hasAgentFee?: boolean; // 结算代理费
    /** 员工工资单项目。薪酬模板-收入项目 */
    payItems?: Payroll.PayItem[];
    payItemsByFieldId?: Record<string, Payroll.PayItem>;
    /** 公司扣缴项目 */
    companyPayments?: SiHfPayItem[];
    companyPaymentsByFieldId?: Record<string, SiHfPayItem>;
    /** 个人扣缴项目 */
    employeePayments?: SiHfPayItem[];
    employeePaymentsByFieldId?: Record<string, SiHfPayItem>;
    payslips?: Payroll.Payslip[]; // todo
    /** 发薪人数 */
    count?: number;
    /** 账单总金额 */
    totalBill?: number;
    bill?: Payroll.Bill;
    status?: Status; // 状态
    invoiced?: boolean; // 是否已开票
    remark?: string; // 备注
    creator?: Creator; // 制表人
    createTime?: string;
    updateTime?: string;
  }

  /** 操作人 */
  interface Creator {
    /** 姓名 */
    name?: string;
  }

  interface PayItem extends Omit<SalaryTemplate.PayItem, 'payType'> {
    payType?: PayItemPayType;
  }

  /** 服务项目。派遣 | 外包 */
  type ServiceType = 'DISPATCH' | 'OUTSOURCING';

  /** 计税方式。 默认 | 单独计税 | 同月工资单合并计税 | 不计个税 */
  type TaxMode = 'NORMAL' | 'ALONE' | 'WITH_MONTH' | 'NOT_COMPUTE';

  /** 创建中 | 待提交 | 审批中 | 审批通过 | 审批拒绝 | 完成 */
  type Status = 'CREATING' | 'AWAITING' | 'APPROVE' | 'APPROVED' | 'DECLINED' | 'COMPLETE';

  /**  */
  type ProcessStatus = 'INPROCESS' | 'COMPLETE' | 'ABORT' | 'CANCEL';
  /** 流程节点任务状态。 */
  type ProcessTaskStatus = 'TODO' | 'ACTIVE' | 'COMPLETE' | 'ABORT' | 'CANCEL' | 'SKIP';

  /** 节点任务 */
  interface ProcessTask {
    id?: string;
    /** 工作流ID */
    processId?: string;
    /** 任务ID。 */
    taskId?: 'submit' | 'approve' | 'confirm';
    /** 任务名称 */
    taskName?: string;
    /** 次序，从0开始 */
    order?: number;
    /** 状态 */
    status?: ProcessTaskStatus;
    executor?: {
      /** 关联IAM平台用户ID */
      sub?: string;
      /** 姓名 */
      name?: string;
    };
    /** 处理内容，审批意见，备注信息等 */
    comment?: string;
    /** 任务结束时间，可能是完成，取消，跳过等的时间 */
    endTime?: string;
  }

  /** 流程审批操作权限code。提交审批 | 撤销审批 | 审批 | 财务确认 */
  type ProcessActionCode =
    | 'payroll_approval_submit'
    | 'payroll_approval_cancel'
    | 'payroll_approve'
    | 'payroll_finance_confirm';
  interface ProcessAction {
    /** 操作项Code */
    code?: ProcessActionCode;
    /** 是否可用 */
    enable?: boolean;
  }

  /** 工作流详情 */
  interface Process {
    /** 工作流ID */
    id?: string;
    // status?:
    /** 是否当前工作流，1个工资单只能有1个进行中的工作流 */
    active?: boolean;
    tasks?: ProcessTask[];
    /** 操作权限，针对当前用户 */
    actions?: ProcessAction[];
    actionsByCode?: Record<ProcessActionCode, ProcessAction>;
  }

  /** 缴纳项目 */
  interface SiHfPayItem {
    fieldId?: string; // 收入项目ID
    payType?: Payroll.PayType; // 收入类型
    name?: string; // 项目名称
    order?: number; // 序号
    remark?: string;
    /** 计算类型 */
    computeType?: 'SCALE' | 'FIXED' | 'FIX_SCALE' | 'FULL_MONTH';
    /** 项目基数 */
    base?: number;
    /** 扣缴比例/固定金额 */
    value?: number;
    /** 小数位数 */
    scale?: number;
  }

  /** 工资记录详情 */
  interface PayDetail {
    fieldId?: string; // 收入项目ID
    amount?: number; // 金额
    payType?: Payroll.PayType; // 收入类型
    name?: string; // 项目名称
    order?: number; // 序号
  }

  /** 个人扣除项目，企业扣除项目，包含社保扣除，公积金扣除和小计 */
  interface SiHfDeduct {
    /** 养老保险 */
    annuity?: number;
    /** 医疗保险 */
    medicare?: number;
    /** 失业保险 */
    unemployment?: number;
    /** 工伤保险 */
    injureAccident?: number;
    /** 生育保险 */
    maternity?: number;
    /** 住房公积金 */
    housingFund?: number;
    /** 小计 */
    total?: number;
    /** 扣除项目列表 */
    payDetails?: PayDetail[];
    /** 这个不会返回，如果要用，需要自行计算添加 */
    payDetailsByFieldId?: Record<string, Payroll.PayDetail>;
  }

  /** 员工工资单 */
  interface Payslip {
    id?: string; // 工资ID
    personId?: string; // 员工ID
    payrollId?: string; // 工资表ID
    payDetails?: Payroll.PayDetail[];
    /** 这个不会返回，如果要用，需要自行计算添加 */
    payDetailsByFieldId?: Record<string, Payroll.PayDetail>;
    siBase?: number; // 社保基数
    housingFundBase?: number; // 住房公积金-基数
    housingFundRate?: number; // 住房公积金-缴存比例
    /** 个人所得税 */
    taxes?: number;
    overdueSi?: number; // 补缴社保
    overdueHousingFund?: number; // 补缴住房公积金
    overdueTax?: number; // 补缴个税
    taxed?: boolean; // 是否已计税

    /** 应发工资 */
    grossWages?: number;
    /** 税后总计 */
    totalNonTaxable?: number;
    /** 实发工资 */
    netPay?: number;
    /** 用工成本 */
    totalCost?: number;
    employeeInfo?: {
      id?: string;
      name?: string;
      phone?: string;
      idNumber?: string;
      workNo?: string;
      payCard?: string;
    };
    personPays?: SiHfDeduct;
    companyPays?: SiHfDeduct;
    /** 个税详情 */
    taxDetail?: TaxDetail;
  }

  /** 员工工资汇总 */
  interface EmployeeWages {
    /** 应发工资 */
    grossWages?: number;
    /** 社会保险 */
    socialInsurance?: number;
    /** 住房公积金 */
    housingFund?: number;
    /** 个人所得税 */
    taxes?: number;
    /** 扣除合计 */
    totalDeductions?: number;
    /** 税后合计 */
    totalNonTaxable?: number;
    /** 实发工资 */
    totalNetPays?: number;
    /** 小计 */
    total?: number;
  }

  /** 公司项目汇总 */
  interface CompanyExpense {
    /** 社会保险 */
    socialInsurance?: number;
    /** 住房公积金 */
    housingFund?: number;
    /** 小计 */
    total?: number;
  }

  /** 服务费收费项目 */
  interface ServiceFee {
    /** 项目名称 */
    name?: string;
    /** 费用类型： 代理费 | 服务费 | 残联金 | 其他 */
    type?: 'AGENT' | 'SERVICE' | 'DISABILITY' | 'OTHER';
    /** 金额 */
    amount?: number;
  }

  /** 账单 */
  interface Bill {
    id?: string;
    payrollId?: string;
    customerId?: string;
    employeeWages?: EmployeeWages;
    companyExpense?: CompanyExpense;
    fees?: Payroll.ServiceFee[];
    /** 服务费合计 */
    totalFee?: number;
    /** 总计 */
    total?: number;
    remark?: string;
  }

  /** 调整类型。按月补缴｜差额调整-个人｜差额调整-公司 */
  type PaySetupType = 'FULL_MONTH' | 'DIFF_EMPLOYEE' | 'DIFF_COMPANY';

  /** 缴纳项-社保类。社保扣缴项(扣除)｜社保补缴项(扣除)｜社保退缴项(返还) */
  type SiPayType = 'SOCIAL_INSURANCE' | 'SOCIAL_INSURANCE_BACK' | 'SOCIAL_INSURANCE_REFUND';
  /** 缴纳项-公积金类。公积金扣缴项(扣除)｜公积金补缴项(扣除)｜公积金退缴项(返还) */
  type HfPayType = 'HOUSING_FUND' | 'HOUSING_FUND_BACK' | 'HOUSING_FUND_REFUND';
  /** 个税调整项。个税补缴｜个税退缴 */
  type TaxPayType = 'TAX_BACK' | 'TAX_REFUND';
  /** payItem字段下的payType */
  type PayItemPayType = SalaryTemplate.PayType | TaxPayType;
  /** 项目类型。包含全部 */
  type PayType = PayItemPayType | SiPayType | HfPayType;

  /** 个税详情 */
  interface TaxDetail {
    id?: string;
    /** 关联员工ID */
    employeeId?: string;
    /** 税款所属期 */
    taxMonth?: string;
    /** 关联工资单ID */
    payslipId?: string;
    /** 合并计税的工资单 */
    withPayslips?: string[];
    /** 本期收入，包含月工资单和福利奖金单的税前收入合计 */
    income?: number;
    /** 免税收入 */
    nonTaxIncome?: number;
    /** 减除费用，当前每月固定5000元，每月只能计算1次 */
    standardDeduction?: number;
    /** 专项扣除明细 */
    specialDeductions?: SpecialDeductionItem[];
    /** 专项扣除合计，包含社保，公积金，每月只能计算1次 */
    totalSpecialDeduction?: number;
    /** 专项附加扣除合计，每月只能计算1次 */
    totalSpecialExpenseDeduction?: number;
    /** 其他扣除项明细 */
    otherDeductions?: OtherDeductionItem[];
    /** 其他扣除合计 */
    totalOtherDeduction?: number;
    /** 应纳税所得额 */
    taxableIncome?: number;
    /** 补/退税额，退税记为负值 */
    taxBacks?: number;
    cumulativeTaxDetails?: CumulativeTaxDetails;
    /** 税款金额 */
    taxes?: number;
    /** 状态 */
    status?: TaxDetailStatus;
  }

  /** 累计个税详情 */
  interface CumulativeTaxDetails {
    /** 累计应纳税所得额 */
    totalTaxableIncome?: number;
  }
  /** 状态，包含完成，进行中，已取消 */
  type TaxDetailStatus = 'COMPLETE' | 'PROCESS' | 'CANCEL';
  /** 个税扣除项专项扣除类型。养老保险 | 医疗保险 | 失业保险 | 社保补缴/退缴 | 住房公共基金 */
  type SpecialDeductionType = 'ANNUITY' | 'MEDICARE' | 'UNEMPLOYMENT' | 'SI_BACKS' | 'HOUSING_FUND';
  interface SpecialDeductionItem {
    type?: SpecialDeductionType;
    /** 扣除金额 */
    amount?: number;
  }
  /** 个税扣除项其他扣除类型。通讯费 */
  type OtherDeductionType = 'PHONE_SUBSIDY';
  interface OtherDeductionItem {
    type?: OtherDeductionType;
    /** 扣除金额 */
    amount?: number;
  }
}

export const payrollApi = {
  get: (params: { [key: string]: any }) =>
    request.get<ResList<Payroll.Self>>('/payroll', { params }),

  getById: (id: string) => request.get<Payroll.Self>(`/payroll/${id}`),

  /** 删除工资单。同时删除账单和员工工资单，不可恢复 */
  delete: (id: string) => request.post(`/payroll/${id}/delete`),

  /** step1-基本设置 */
  addFirst: (data) => request.post<Payroll.Self>('/payroll', data),
  /** 工资单列表 */
  getTaxWith: (params) => request.get<Payroll.Self[]>('/payroll/tax_with', { params }),
  /** 修改工资单基本信息 */
  editFirst: (id: string, data) => request.post<Payroll.Self>(`/payroll/${id}`, data),
  /** 工资单-step2-根据员工列表获取工资表 */
  payslipListAdd: (id: string, data) =>
    request.post<Payroll.Payslip[]>(`/payroll/${id}/payslip_list`, data),
  /** 根据员工工资单id，编辑/修改员工工资详情 */
  payslipEdit: (payslipId: string, data) =>
    request.post<Payroll.Payslip>(`/payroll/payslip/${payslipId}`, data),
  /** 从当前工资单中移除该员工，相关员工工资单将删除 */
  payslipRemove: (payrollId: string, payslipId: string) =>
    request.post(`/payroll/${payrollId}/payslip/${payslipId}/remove`),
  /** 根据工资单ID保存，保存并提交 */
  submitPayroll: (id: string, submitType: 'SAVE' = 'SAVE') =>
    request.post<Payroll.Self>(`/payroll/${id}/submit`, { submitType }),

  /** 修改备注 */
  editRemark: (id: string, data) => request.post<Payroll.Self>(`/payroll/${id}/remark`, data),
  /** deprecated。修改工资单状态。不可修改为 CREATING，修改为 INVOICED 后不可再进行修改 */
  // editStatus: (id: string, data) => request.post<Payroll.Self>(`/payroll/${id}/status`, data),

  /** 查询工资单-处理流程详情 */
  getProcess: (id: string) => request.get<Payroll.Process>(`/payroll/${id}/process`),
  /** 提交审批，提交并开始处理流程 */
  submitProcess: (id: string) => request.post(`/payroll/${id}/process`),
  /** 对待审批的工资单进行审批 */
  approve: (id: string, data) => request.post(`/payroll/${id}/approve`, data),
  /** 财务确认，进行工资单确认 */
  financialConfirmProcess: (id: string, data) => request.post(`/payroll/${id}/confirm`, data),
  /** 撤销审批。撤销，中断，取消流程 */
  cancelProcess: (id: string) => request.delete(`/payroll/${id}/process`),

  /** 根据员工id增加工资条 */
  addPayslip: (id: string, data: { id: string }) => request.post(`/payroll/${id}/payslip`, data),
  /** 根据工资单ID批量增加收入项，可批量设置金额 */
  addPayItem: (id: string, data) => request.post(`/payroll/${id}/payItem`, data),
  /** 根据工资单ID批量增加扣缴项，可批量增加社保补/退缴，并重新计算各项合计 */
  addSocialInsurance: (id: string, data) => request.post(`/payroll/${id}/socialInsurance`, data),
  /** 根据工资单ID批量增加扣缴项，可批量增加公积金补/退缴，并重新计算各项合计 */
  addHousingFund: (id: string, data) => request.post(`/payroll/${id}/housingFund`, data),
  /** 根据工资单ID批量增加个税调整项，可批量设置金额 */
  addTaxPayItem: (id: string, data) => request.post(`/payroll/${id}/taxPayItem`, data),

  /** 结算账单，服务费用项目编辑修改 */
  editBillFee: (billId: string, data: Payroll.Bill['fees']) =>
    request.post(`/bill/${billId}/fee`, data),
};
