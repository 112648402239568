import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dayjs } from 'dayjs';
import { ResPagination } from '@/utils/request';
import { Bill } from '@/pages/bill/api';

export interface BillState {
  list: Bill.Self[];
  pagination: Partial<ResPagination>;
  searchData: {
    startMonth?: string;
    endMonth?: string;
    startEndMonth?: Dayjs[];
    customerId?: string;
  };
}

export const initialSD: BillState['searchData'] = {};

const initialState: BillState = {
  list: [],
  pagination: { number: 0, size: 10 },
  searchData: initialSD,
};

const slice = createSlice({
  name: 'bill',
  initialState,
  reducers: {
    update: (state, { payload }: PayloadAction<Partial<BillState>>) => ({
      ...state,
      ...payload,
    }),
  },
});

export const billActions = slice.actions;
export const billSlice = slice;
export * as billThunks from './thunks';
