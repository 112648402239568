import { dayjsToStr } from '@/utils';
import { ReqList } from '@/utils/request';
import { AppThunk } from '@/store';
import { payrollApi } from '@/pages/payroll/api';
import { payrollActions, PayrollState } from '.';

const sdToReq = (sd: PayrollState['searchData']) => {
  const { monthDayjs, ...rest } = sd;
  return { ...rest, month: dayjsToStr(monthDayjs, 3) } as PayrollState['searchData'];
};

export const getList =
  (payload: ReqList = {}): AppThunk =>
  async (d, g) => {
    const { page, size, ...rest } = payload;
    const { pagination: curP, searchData } = g().payroll;
    const req: ReqList = {
      ...sdToReq(searchData),
      page: page ?? curP.number,
      size: size ?? curP.size,
      ...rest,
    };
    const res = await payrollApi.get(req);
    // if (process.env.NODE_ENV === 'development') {
    //   res.data.content[res.data.content.length - 1].status = 'CREATING';
    // }
    const { content, ...pagination } = res.data || {};
    d(payrollActions.update({ list: content || [], pagination }));
  };

export const getDetailIfNeed =
  (id: string, force = false): AppThunk =>
  async (d, g) => {
    if (!force && id === g().payroll.detail.id) return;

    const res = await payrollApi.getById(id);
    // if (process.env.NODE_ENV === 'development') {
    //   if (res.data.bill) {
    //     res.data.bill.fees = [
    //       { name: '代理费', type: 'AGENT', amount: 3361.61 },
    //       { name: '服务费', type: 'SERVICE', amount: 3361.61 },
    //       { name: '残联金', type: 'DISABILITY', amount: 3361.61 },
    //     ];
    //   }
    // }
    // if (process.env.NODE_ENV === 'development') {
    //   res.data.status = 'COMPLETE';
    // }
    // if (process.env.NODE_ENV === 'development') {
    //   res.data.payrollType = 'BONUS';
    // }
    d(payrollActions.update({ detail: res.data || {} }));
  };
