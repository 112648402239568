import { lazy } from 'react';
import { HomeOutlined } from '@ant-design/icons';
import { RouteItem } from './types';

const overview: RouteItem[] = [
  {
    path: '/overview',
    component: lazy(
      () => import(/* webpackChunkName: "overview" */ '@/pages/overview/Index'),
    ),
    title: '概览',
    icon: <HomeOutlined />,
  },
];

export default overview;
