import request, { ResList } from '@/utils/request';

export declare namespace Bill {
  interface Self {
    /** 月份 */
    month?: string;
    /** 用工单位ID */
    customerId?: string;
    /** 用工单位 */
    customerName?: string;
    /** 公司ID */
    companyId?: string;
    /** 公司名称 */
    companyName?: string;
    /** 员工工资-总计 */
    totalEmployeeWages?: number;
    /** 单位费用-总计 */
    totalCompanyExpense?: number;
    /** 用工成本-总计 */
    totalCost?: number;
    /** 服务费-总计 */
    totalFee?: number;
    /** 结算金额-总计 */
    total?: number;
    /** 经办人 */
    agent?: string;
    /** 开始月份 */
    startMonth?: string;
    /** 结束月份 */
    endMonth?: string;
  }
}

export const billApi = {
  /** 用于结算管理，用工成本列表页的统计 */
  getAnalysis: (params) => request.get<ResList<Bill.Self>>('/bill/analysis', { params }),
};
