import request, { ResList } from '@/utils/request';

export declare namespace Tax {
  interface Self {
    /** 员工ID */
    employeeId?: string;
    /** 员工姓名 */
    name?: string;
    /** 身份证号 */
    idNumber?: string;
    /** 经办人 */
    agent?: string;
    /** 关联客户企业ID */
    customerId?: string;
    /** 关联客户企业名称 */
    customerName?: string;
    /** 年度-纳税记录年度 */
    fiscalYear?: number;
    /** 累计收入 */
    totalIncome?: number;
    /** 累计减除费用 */
    totalStandardDeduction?: number;
    /** 累计专项扣除 */
    totalSpecialDeduction?: number;
    /** 累计专项附加扣除 */
    totalSpecialExpenseDeduction?: number;
    /** 累计其他扣除 */
    totalOtherDeduction?: number;
    /** 累计应纳税所得额 */
    totalTaxableIncome?: number;
    /** 累计补缴/退缴税款 */
    totalTaxBacks?: number;
    /** 累计个税，累计应纳税额 */
    totalTaxes?: number;
    /** 税率/预扣率 */
    rates?: number;
    /** 速算扣除数 */
    quickDeduction?: number;
    // taxDetails?:                   TaxDetail[];
  }
}

export const taxApi = {
  /** 根据员工范围批量查询个税 */
  get: (params) => request.get<ResList<Tax.Self>>('tax', { params }),

  /** 查询年度累计个人所得税 */
  getTaxYear: (params: { employeeId: string; year: number | string }) =>
    request.get<Tax.Self>('/tax/tax_year', { params }),
};
