import constant from '@/constant';
import { arrToObjByValue } from '@/utils';
import { AppThunk } from '@/store';
import { Oauth, oauthApi } from '@/api/oauth';
import { dictApi } from '@/api/dict';
import { settingApi } from '@/api/setting';
import { Company, companyApi } from '@/api/company';
import { agentApi } from '@/api/agent';
import { commonActions, CommonState } from './index';

export const getAgents = (): AppThunk => async (d, g) => {
  const res = await agentApi.get();
  d(commonActions.update({ agents: res.data || [] }));
};

export const logout = (): AppThunk => async (dispatch) => {
  dispatch(commonActions.update({ oauthUser: {} }));
  window.localStorage.removeItem(constant.storageKeys.oauthToken);
};

/**
 * 尝试获取登录的用户信息
 * @param needChangeLoginingStatus 是否需要改变全局登录loading状态。直接进入页面需要，登录页点击登录后不需要
 * @returns 是否是登录状态获取到了用户信息
 */
export const tryGetOauthUser =
  (needChangeLoginingStatus = true): AppThunk<Promise<boolean>> =>
  async (dispatch) => {
    const oauthToken: Oauth.OauthToken = JSON.parse(
      window.localStorage.getItem(constant.storageKeys.oauthToken) || '{}',
    );
    if (!oauthToken.access_token) return false;

    if (needChangeLoginingStatus) {
      dispatch(commonActions.update({ isLogining: true }));
    }
    const res = await oauthApi.getUser();
    if (res.code !== 200) {
      dispatch(commonActions.update({ isLogining: false }));
      return false;
    }

    dispatch(getAgents());

    const ou = res.data || {};
    const rm = (ou.roles || []).reduce<NonNullable<Oauth.User['roleMap']>>((acc, cur) => {
      acc[cur] = true;
      return acc;
    }, {});
    const temp: Oauth.User = { ...ou, roleMap: rm };
    dispatch(commonActions.update({ isLogining: false, oauthUser: temp }));
    return true;
  };

export const getDictIfNeed =
  ({
    dictCode,
    force = false,
  }: {
    dictCode: keyof CommonState['dictByCode'] | (keyof CommonState['dictByCode'])[];
    force?: boolean;
  }): AppThunk =>
  async (d, g) => {
    const codes = Array.isArray(dictCode) ? dictCode : [dictCode];

    const pre = g().common.dictByCode;
    // 如果强制更新，将全量更新。否则只更新没有数据的项
    const codesNeedUpdate = force ? codes : codes.filter((v) => !pre[v].id);
    if (codesNeedUpdate.length === 0) return;

    const loadingToUpdate = codesNeedUpdate.reduce<Partial<CommonState['dictByCode']>>(
      (acc, cur) => {
        // @ts-ignore
        acc[cur] = {
          ...pre[cur],
          loading: true,
        };
        return acc;
      },
      {},
    );
    d(commonActions.update({ dictByCode: { ...pre, ...loadingToUpdate } }));

    const promises = codesNeedUpdate.map((v) => dictApi.getByCode(v));
    const resArr = await Promise.all(promises);

    const objToUpdate = resArr.reduce<Partial<CommonState['dictByCode']>>((acc, res, index) => {
      // @ts-ignore
      acc[codesNeedUpdate[index]] = {
        ...res.data,
        sorted: res.data.dictItems?.slice().sort((a, b) => {
          return (a.sort || 0) - (b.sort || 0);
        }),
        byId: arrToObjByValue(res.data.dictItems!),
        loading: false,
      };
      return acc;
    }, {});
    d(commonActions.update({ dictByCode: { ...pre, ...objToUpdate } }));
  };

export const getSettingIfNeed = (): AppThunk => async (d, g) => {
  const pre = g().common.setting;
  if (pre.id) return;

  const res = await settingApi.get();
  d(commonActions.update({ setting: res.data }));
};

/** 获取所有接收单位，如果为空 */
export const getCompanysIfNeed = (): AppThunk => async (d, g) => {
  const pre = g().common.companys;
  if (pre.data.length > 0) return;

  d(commonActions.update({ companys: { ...pre, loading: true } }));
  const res = await companyApi.get();
  const data = (res.data || []).map((v) => ({
    ...v,
    employeePayRulesByFieldId: arrToObjByValue(
      v.employeePayRules || [],
      'fieldId' as keyof Company.PayRule,
    ),
    companyPayRulesByFieldId: arrToObjByValue(
      v.companyPayRules || [],
      'fieldId' as keyof Company.PayRule,
    ),
  }));
  const byId = arrToObjByValue(data, 'id');
  d(commonActions.update({ companys: { ...pre, loading: false, data, byId } }));
};
