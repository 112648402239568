import { AppThunk } from '@/store';
import { dayjsToStr } from '@/utils';
import { ReqList } from '@/utils/request';
import { taxApi } from '@/pages/salary/tax/api';
import { taxActions, TaxState } from '.';

const sdToReq = (sd: TaxState['searchData']) => {
  const { yearDayjs, ...rest } = sd;
  return { ...rest, year: dayjsToStr(yearDayjs, 4) } as TaxState['searchData'];
};

export const getList =
  (payload: ReqList = {}): AppThunk =>
  async (d, g) => {
    const { page, size, ...rest } = payload;
    const { pagination: curP, searchData } = g().tax;
    const req: ReqList = {
      ...sdToReq(searchData),
      page: page ?? curP.number,
      size: size ?? curP.size,
      ...rest,
    };

    const res = await taxApi.get(req);
    const { content, ...pagination } = res.data || {};
    d(taxActions.update({ list: content || [], pagination }));
  };
