import request, { ReqList, ResList } from '@/utils/request';
import { Company } from '@/api/company';
import { Employer } from '@/pages/employer/api';
import { SalaryTemplate } from '@/pages/salary/Template/api';

export declare namespace Employee {
  /** 员工信息 */
  interface Self {
    id?: string;
    name?: string;
    birthday?: string;
    age?: number;
    retireDate?: string; // 退休年月
    gender?: string;
    phone?: string;
    idNum?: IdInfo;
    hhrType?: string; // 户籍类型，HouseHold Register Type
    hhrRegion?: string; // 户口所在地，HouseHold Register region
    country?: string; //国家/地区
    edu?: string; // 学历
    disabled?: boolean; // 是否为残疾人
    disabledCert?: string; // 残疾证号
    /** 工资卡号 */
    payCard?: string;
    laborContract?: LaborContract;
    job?: JobState;
    customer?: Employer.EmployerSimple;
    record?: Employee.RecordState;
    /** 备注 */
    remark?: string;
    wages?: Employee.Wages;
    template?: SalaryTemplate.Self;
    socialInsurance?: Employee.SocialInsurance;
    housingFund?: Employee.HousingFund;
    createTime?: string;
    specialDeductions?: Employee.SpecialDeductionItem[];
  }

  /** 证件信息 */
  interface IdInfo {
    idType?: string; // 证件类型
    idNumber?: string;
  }

  /** 在岗状态 */
  interface JobState {
    jobState?: 0 | 1; // 工作状态，0 离岗，1 在岗
    entryDate?: string; // 入职日期
    departDate?: string; // 离职日期
    customerId?: string; // 用工企业id
    customer?: Employer.EmployerSimple;
    workType?: string; // 工种
    workNo?: string; // 工号
    dept?: string; // 部门
    post?: string; // 职位
  }

  /** 劳动合同 */
  interface LaborContract {
    /** 签约状态，0 未签约，1 已签约, 2 已解除 */
    contractState?: 0 | 1 | 2;
    contractNo?: string; // 合同编号
    startDate?: string; // 合同开始时间
    endDate?: string; // 合同结束/解除时间
    limit?: number; // 合同期限
    /** 签约公司 */
    companyId?: string;
  }

  /** 档案状态 */
  interface RecordState {
    recordNo?: string; // 档案编号
    /** 档案状态。 0 未接收，1 已接收，2 已转出，3 已入库 */
    recordState?: 0 | 1 | 2 | 3;
    oldRecordNo?: string; // 档案编号（原）
    /** 接收单位id */
    companyId?: string;
    /** 接收日期 */
    handleData?: string;
    agent?: string; // 经办人
    agentId?: string;
    remarks?: string;
  }

  /** 收入项目金额 */
  interface WagesItem {
    fieldId?: string; // 收入项目id
    wage?: number; // 收入金额
  }

  /** 员工薪酬 */
  interface Wages {
    templateId?: string; // 薪酬模板ID
    template?: SalaryTemplate.Self;
    wagesItems?: Employee.WagesItem[];
    total?: number;
    // totalWages?: number;
    changeDate?: string; // 调薪日期
    changeReason?: string; // 调薪原因
  }

  /** 社会保险 */
  interface SocialInsurance {
    paid?: boolean; // 是否缴纳
    base?: number; // 社保基数
    /** 是否只缴纳工伤保险 */
    injureAccidentOnly?: boolean;
    startMonth?: string; // 起始缴纳月份
    endMonth?: string; // 最后缴纳月份
  }

  /** 住房公积金 */
  interface HousingFund {
    /** 是否缴纳 */
    paid?: boolean;
    /** 公积金基数 */
    base?: number;
    /** 缴存比例 */
    rate?: number;
    /** 个人账户 */
    privateAccount?: string;
  }

  interface SiDetailPaymentItem {
    /** 项目ID */
    fieldId?: string;
    /** 名称 */
    name?: string;
    /** 金额 */
    amount?: number;
    /** 基数 */
    base?: number;
    /** 比例 */
    rate?: number;
    /** 社保险种 */
    insuranceType?: Company.InsuranceType;
  }

  /** 社保设置详情 */
  interface SiDetail {
    /** 员工ID */
    employeeId?: string;
    /** 公司缴纳账号 */
    siAccount?: string;
    /** 公司名称 */
    companyName?: string;
    /** 社保基数 */
    siBase?: number;
    /** 只缴工伤保险 */
    injureAccidentOnly?: boolean;
    /** 个人缴纳详情 */
    employeePays?: SiDetailPaymentItem[];
    employeePaysByInsuranceType?: Record<Company.InsuranceType, SiDetailPaymentItem>;
    /** 个人缴纳合计 */
    totalEmployee?: number;
    /** 公司缴纳详情 */
    companyPays?: SiDetailPaymentItem[];
    companyPaysByInsuranceType?: Record<Company.InsuranceType, SiDetailPaymentItem>;
    /** 公司缴纳合计 */
    totalCompany?: number;
  }

  /** 专项附加扣除 */
  interface SpecialDeductionItem {
    /** 专项附加扣除类型： 子女教育 ｜ 赡养老人 ｜ 住房租金 ｜ 住房贷款利息 ｜ 继续教育 ｜ 大病医疗 */
    type?:
      | 'CHILDREN_EDU'
      | 'ELDERLY_CARE'
      | 'RENT'
      | 'HOUSING_LOAN_INTERESTS'
      | 'CONTINUING_EDU'
      | 'SERIOUS_DISEASE_TREATMENT';
    /** 扣除金额/每月 */
    amount?: number;
  }

  // interface ResolveIdCardRes {

  // }

  /** 解析身份中结果 */
  type ResolveIdCardRes = Pick<Self, 'birthday' | 'gender' | 'hhrRegion' | 'retireDate'>;
}

export const employeeApi = {
  get: (params?: ReqList) => request.get<ResList<Employee.Self>>('/person', { params }),

  getById: (id: string) => request.get<Employee.Self>(`/person/${id}`),

  add: (data) => request.post<Employee.Self>('/person', data),

  edit: (id: string, data) => request.post<Employee.Self>(`/person/${id}`, data),

  /** 调薪，设置员工薪酬 */
  wagesEdit: (id: string, data) => request.post<Employee.Self>(`/person/${id}/wages`, data),

  /** 激活调整社保基数，参保账户 */
  siEdit: (id: string, data) => request.post<Employee.Self>(`/person/${id}/si_edit`, data),

  /** 查询社保详情。根据员工查询社保设置及详情信息，已签约且设置了社保信息的员工可以查询到社保详情 */
  getSiById: (id: string) => request.get<Employee.SiDetail>(`/person/${id}/si`),

  /** 签约/续签 */
  sign: (id: string, data: Employee.LaborContract) =>
    request.post<Employee.Self>(`/person/${id}/sign`, data),

  /** 解约 */
  contractCancel: (id: string, data) =>
    request.post<Employee.Self>(`/person/${id}/contract_cancel`, data),

  /** 入职 */
  jobEntry: (id: string, data) => request.post<Employee.Self>(`/person/${id}/job_entry`, data),

  /** 离职 */
  jobDepart: (id: string, data) => request.post<Employee.Self>(`/person/${id}/job_depart`, data),

  /** 接收员工纸质档案文件 */
  receiveRecord: (id: string, data) => request.post(`/person/${id}/record_receive`, data),
  /** 修改档案信息，不可修改档案号 */
  editRecord: (id: string, data) => request.post<Employee.Self>(`/person/${id}/record`, data),

  /** 修改备注 */
  editRemark: (id: string, data) => request.post(`/person/${id}/remark`, data),

  /** 激活住房公积金，公积金基数，缴存比例设置 */
  housingFundEdit: (id: string, data) =>
    request.post<Employee.Self>(`/person/${id}/housingfund`, data),

  /** 设置专项附加扣除，只能设置今年 */
  specialDeductionEdit: (id: string, data) =>
    request.post(`/person/${id}/special_deductions`, data),

  /** 按薪酬模板查询 */
  getBySalaryTemplate: (params: ReqList & { template: string }) =>
    request.get<ResList<Employee.Self>>('/person_query', { params }),

  /** 薪酬管理，支持员工薪酬，社保管理，公积金管理列表页 */
  getPersonWages: (params?: ReqList) => request.get('/person_query/wages', { params }),

  /** TODO 根据入职企业ID查询员工 */
  getByQuery: (
    params: {
      name?: string;
      customer?: string;
      template?: string;
      idNumber?: string;
    } & ReqList,
  ) => request.get<ResList<Employee.Self>>('/person_query/searchs', { params }),

  /** 人事管理，员工合同，退休管理，员工档案列表页 */
  getPersonPersonal: (params?: ReqList) =>
    request.get<ResList<Employee.Self>>('/person_query/personal', { params }),

  /** 人事管理-档案管理列表 */
  getPersonRecords: (params?: ReqList) =>
    request.get<ResList<Employee.Self>>('/person_query/records', { params }),

  /** 解析身份证号 */
  resolveIdCard: (idCard: string) =>
    request.get<Employee.ResolveIdCardRes>('/person/idCard', { params: { idCard } }),
};
