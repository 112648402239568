import { Dayjs } from 'dayjs';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResPagination } from '@/utils/request';
import { Payroll } from '@/pages/payroll/api';

export interface PayrollState {
  list: Payroll.Self[];
  pagination: Partial<ResPagination>;
  detail: Payroll.Self;
  searchData: {
    customerId?: string;
    // payrollType: '';
    month?: string;
    monthDayjs?: Dayjs;
    // status: '';
    creator?: string;
  };
}

export const initialSD: PayrollState['searchData'] = {};

const initialState: PayrollState = {
  list: [],
  pagination: { number: 0, size: 10 },
  detail: {},
  searchData: initialSD,
};

const slice = createSlice({
  name: 'payroll',
  initialState,
  reducers: {
    update: (state, { payload }: PayloadAction<Partial<PayrollState>>) => ({
      ...state,
      ...payload,
    }),
  },
});

export const payrollActions = slice.actions;
export const payrollSlice = slice;
export * as payrollThunks from './thunks';
