import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResPagination } from '@/utils/request';
import { SalaryTemplate } from '@/pages/salary/Template/api';

export interface State {
  list: SalaryTemplate.Self[];
  pagination: Partial<ResPagination>;
  detail: SalaryTemplate.Self;
}

const initialState: State = {
  list: [],
  pagination: { number: 0, size: 10 },
  detail: {},
};

const slice = createSlice({
  name: 'salaryTemplate',
  initialState,
  reducers: {
    update: (state, { payload }: PayloadAction<Partial<State>>) => ({
      ...state,
      ...payload,
    }),
  },
});

export const salaryTemplateActions = slice.actions;
export const salaryTemplateSlice = slice;
export * as salaryTemplateThunks from './thunks';
