import constant from '@/constant';
import dayjs, { Dayjs } from 'dayjs';

/** 将数组转成对象，以每项的唯一标识作为key */
export const arrToObjByValue = <T extends { [key: string]: any }>(
  arr: T[],
  idKey = 'value',
): Record<string, T> =>
  arr.reduce((acc, cur) => {
    acc[cur[idKey]] = cur;
    return acc;
  }, {});

/** dayjs 对象转成字符串形式 */
export const dayjsToStr = (d?: Dayjs, type: 1 | 2 | 3 | 4 | string = 1) => {
  if (!d) return d;
  const obj = {
    1: 'YYYY-MM-DD',
    2: 'YYYY-MM-DD HH:mm:ss',
    3: 'YYYY-MM-01', // 因为后端处理 YYYY-MM 格式不方便，如果只需要年月信息的话，可使用这种格式
    4: 'YYYY',
  };
  const tem = obj[type];
  return d.format(tem || type);
};

export const limitStr = (str?: string, end = '...') => {
  if (!str) return '';
  if (str.length < 15) return str;
  return `${str.substring(0, 15)}${end}`;
};

export const filterObj = <T extends { [key: string]: any }>(obj: T) => {
  const res: Partial<T> = {};
  for (const p in obj) {
    if (![null, undefined, ''].includes(obj[p])) {
      res[p] = obj[p];
    }
  }
  return res;
};

/** 时间与今天相比在多少天之内，默认30天内 */
export const isRedDate = (d?: string, dis = 30) => {
  const dDayjs = dayjs(d);
  if (!(d && dDayjs.isValid())) return false;

  return dDayjs.subtract(dis, 'day').isBefore(constant.today, 'day');
};
